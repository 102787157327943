/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import { v4 as uuidv4 } from 'uuid';
import { Avatar } from "@mui/material";
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import userAvatar from '../../assets/userAvatar.svg';
// Custom Import
import { sendMessage } from '../connection/websocket';
import FormWithApiCall from './Form';

export const ChatBody = ({ messageInfo, setIsTyping, setMessages }) => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [formVisibility, setFormVisibility] = useState(false);

    const handleOptionClick = (event, option) => {
        event.target.disabled = true;
        setIsTyping(true);
        const newMessage = {
            id: uuidv4(),
            sender: "user",
            message: {
                type: 'text',
                body: option.title
            },
            timestamp: new Date(),
        };
        console.log(option.reply)
        if (option.reply === 'email support' && localStorage.getItem('name')=="" && localStorage.getItem('email')=="") {
            // Only execute below codes when get user-info api response back with blank user name, email
            // Show the forms
            // Call update API
            setFormVisibility(true);
            setIsTyping(false);
            console.log("Name and email is empty trying to fill it");
            console.log("localStorage.name "+localStorage.name);
            console.log("localStorage.email "+localStorage.email);  
        }
        else{
           sendMessage({ id: newMessage.id, sender: newMessage.sender, message: option.reply, time: Math.floor(Date.now() / 1000) });
           setMessages((prevMessages) => [...prevMessages, newMessage]);
        }
    };

    return (
        <div>
        <div id={messageInfo.id} key={messageInfo.id} className={`chat-message ${messageInfo.sender}`}>
            <div className="message-container">
                {messageInfo.sender === "bot" && (
                    <Avatar className="bot-avatar">
                    <img src={userAvatar} alt=""/>
                  </Avatar>
                )}
                <div>
                    <div className="message-text">
                        {messageInfo.message.body}
                        {/* <div className="timestamp">
                            {messageInfo.timestamp.toLocaleTimeString()}
                        </div> */}
                    </div>
                    {messageInfo.message.type.toLowerCase() === 'button' && (
                        <div className="message-options">
                            {messageInfo.message.buttons.map((option, idx) => (
                                <button
                                    key={idx}
                                    onClick={(event) => handleOptionClick(event, option)}
                                    className="option-button"
                                >
                                    {option.title}
                                </button>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </div>
        {formVisibility && <FormWithApiCall setFormVisibility={setFormVisibility} setMessages={setMessages} setIsTyping={setIsTyping} />}
        </div>
    )
}