import React, { useState, useEffect } from "react";
import Chatbot from "./components/chatbot/Chatbot";
import ChatIcon from "./components/chatIcon/ChatIcon";
import { initializeWebSocket } from "./components/connection";

function App() {
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [isTyping, setIsTyping] = useState(false);
  const [currentIframeSize, setCurrentIframeSize] = useState({ width: "", height: "" });


  const changeIframeSize = (width, height,allowMediaQuery) => {
    console.log("size from iframesize",width,height)
    if (currentIframeSize.width === width && currentIframeSize.height === height) {
      return;
    }
    setCurrentIframeSize({ width, height }); 
    window.parent.postMessage(
      {
        type: "resize-iframe",
        width: width,
        height: height,
        windowState:isChatOpen
      },
      "https://www.hellobe.com/"
    );
  };
  useEffect(()=>{
    if(isChatOpen){
      changeIframeSize("350px","540px",false)
    }
    changeIframeSize("100px","100px",true)
    console.log("window",window.innerWidth)
  },[isChatOpen])


  const toggleChat = () => {
    console.log("click triggered")
    setIsChatOpen((prev) => !prev);
  };

  useEffect(() => {
    initializeWebSocket(setMessages, setIsTyping);
  }, []);


  return (
    <div className="App">
      {isChatOpen && (
        <Chatbot
          onClose={toggleChat}
          messages={messages}
          setMessages={setMessages}
          isTyping={isTyping}
          setIsTyping={setIsTyping}
        />
      )}
      {!isChatOpen && (
        <ChatIcon
          onClick={toggleChat}
          onMouseOver={toggleChat}
          setMessages={setMessages}
          setIsTyping={setIsTyping}
        />
      )}
    </div>
  );
}

export default App;