import { v4 as uuidv4 } from 'uuid';
import React, { useState } from 'react';
import axios from 'axios';
import './form.css';
// Custom Import
import { sendMessage } from '../connection/websocket';

const FormWithApiCall = ({ setFormVisibility, setMessages, setIsTyping }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  // Handle form submit and make API call
  const handleSubmit = async (event) => {
    event.preventDefault();

    // Set loading to true when API call starts
    setIsLoading(true);
    setIsTyping(true);
    setError('');
    setResponseMessage('');

    const formData = {
        id: localStorage.getItem('userId'),
        name: name,
        email: email,
        whatsAppUserName: '',
        whatsAppNumber: '',
        conversationId: localStorage.getItem('ConvId')
    };

    try {
      // API call (POST request)
     // const response = await axios.put('http://127.0.0.1:8081/api/be-bot/user-info/update', formData);
      const response = await axios.put(`https://${window.location.hostname}/api/be-bot/user-info/update`, formData);
     
      if (response.status === 204) {
        setFormVisibility(false);
        localStorage.setItem('name', name);
        localStorage.setItem('email', email);
        const newMessage = {
            id: uuidv4(),
            sender: "user",
            message: {
                type: 'text',
                body: 'email support'
            },
            timestamp: new Date(),
        };
        sendMessage({ id: newMessage.id, sender: newMessage.sender, message: 'email support', time: Math.floor(Date.now() / 1000) });
        setMessages((prevMessages) => [...prevMessages, newMessage]);
      }

      // Handle the response if successful
    } catch (err) {
      // Handle errors
      setError('An error occurred while submitting the form.');
      console.error('API error:', err);
    } finally {
      // Set loading to false after request completes
      setIsLoading(false);
    }
  };

  return (
    
    <div className='container'>
      <h3 className='submit-info-heading'>Submit Your Information</h3>
      <form onSubmit={handleSubmit}>
        <div className='name-container'>
          <label htmlFor="name" className='label'>Name:</label>
          <input
          className='input-element email-input'
            type="text"
            id="name"
            name="name"
            value={name}
            placeholder='Enter your Name'
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="email" className='label'>Email:</label>
          <input
          className='input-element email-input'
            type="email"
            id="email"
            name="email"
            placeholder='Enter your Email'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className='button'>
          <button type="submit" disabled={isLoading} style={{color:"white"}}>
            {isLoading ? 'Submitting...' : 'Submit'}
          </button>
        </div>
      </form>

      {responseMessage && <p>{responseMessage}</p>}
      {error && <p style={{ color: 'red' }}>{error}</p>}
    </div>
  );
};

export default FormWithApiCall;
