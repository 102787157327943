import React from "react";
import chatLogo from '../../assets/chatLogo.svg'
import "./index.css";
 
const ChatIcon = ({ onClick, setMessages, setIsTyping }) => {
 
 
  return (
    <div className="chat-icon" onClick={onClick}>
      <img src={`${chatLogo}?v=${new Date().getTime()}`} alt="icon" style={{height:"100px",width:"100px"}}/>
    </div>
  );
};
 
export default ChatIcon;