/* eslint-disable no-unused-vars */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  import { v4 as uuidv4 } from "uuid";
  import { MdDelete, MdSend } from "react-icons/md";
  import BotIcon from "@mui/icons-material/SmartToy";
  import { Avatar, IconButton } from "@mui/material";
  import ClearIcon from "@mui/icons-material/Clear";
  import React, { useState, useEffect, useRef } from "react";
  import { HambergerMenu, Trash, Call, Notepad2, Send } from "iconsax-react";
  import List from "@mui/material/List";
  import ListItem from "@mui/material/ListItem";
  import ListItemIcon from "@mui/material/ListItemIcon";
  import ListItemText from "@mui/material/ListItemText";
  import botlogo from "../../assets/botlogo.svg";
  import { Stack, Typography } from "@mui/material";
  import userAvatar from '../../assets/userAvatar.svg'
  
  
  
  import "./index.css";
  
  // Custom Import
  import "./index.css";
  import { ChatBody } from "./ChatBody";
  import { CHAT_BOT } from "../common/label-constants";
  import { sendMessage } from "../connection/websocket";
  
  const Chatbot = ({ onClose, messages, setMessages, isTyping, setIsTyping }) => {
    const [userInput, setUserInput] = useState("");
    const [openAccountStep, setOpenAccountStep] = useState(0);
    const [accountDetails, setAccountDetails] = useState({
      name: "",
      phone: "",
      email: "",
      gender: "",
    });
  
    const chatEndRef = useRef(null);
  
    const openAccountQuestions = [
      "What is your name?",
      "What is your phone number?",
      "What is your email address?",
      "What is your gender?",
    ];
  
    useEffect(() => {
      document.getElementById("user-input").focus();
    }, []);
  
    useEffect(() => {
      chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }, [messages]);
  
    const handleUserInput = (e) => setUserInput(e.target.value);
  
    const handleSend = () => { 
      if (!userInput.trim()) return;
      const processedInput = addQuestionMarkIfNeeded(userInput);
      const newMessage = {
        id: uuidv4(),
        sender: "user",
        message: {
          type: "text",
          body: processedInput,
        },
        timestamp: new Date(),
      };
      setMessages((prevMessages) => [...prevMessages, newMessage]);
      setIsTyping(true);
      setUserInput("");
      sendMessage({
        id: newMessage.id,
        sender: newMessage.sender,
        message: processedInput,
        time: Math.floor(Date.now() / 1000),
      });
    };
  
    const addQuestionMarkIfNeeded = (input) => {
      const questionWords = [
        "why",
        "how",
        "who",
        "what",
        "where",
        "when",
        "is",
        "are",
        "do",
        "does",
      ];
      const trimmedInput = input.trim();
      const startsWithQuestionWord = questionWords.some((word) =>
        trimmedInput.toLowerCase().startsWith(word)
      );
  
      if (startsWithQuestionWord && !trimmedInput.endsWith("?")) {
        return trimmedInput + "?";
      }
      return trimmedInput;
    };
  
    // const handleMockResponse = (userText) => {
    //     setIsTyping(true);
    //     const normalizedText = userText.toLowerCase().trim();
    //     const response = mockData[normalizedText] || mockData.default;
  
    //     setTimeout(() => {
    //         setMessages((prevMessages) => [
    //             ...prevMessages,
    //             {
    //                 sender: "bot",
    //                 text: response.text || response,
    //                 timestamp: new Date(),
    //             },
    //         ]);
  
    //         if (response.options) {
    //             setMessages((prevMessages) => [
    //                 ...prevMessages,
    //                 {
    //                     sender: "bot",
    //                     options: response.options,
    //                     timestamp: new Date(),
    //                 },
    //             ]);
    //         }
    //         setIsTyping(false);
    //     }, 1000);
    // };
  
    // const botReply = (replyText) => {
    //     setIsTyping(true);
    //     setTimeout(() => {
    //         setMessages((prevMessages) => [
    //             ...prevMessages,
    //             { sender: "bot", text: replyText, timestamp: new Date() },
    //         ]);
    //         setIsTyping(false);
    //     }, 1000);
    // };
  
    const handleKeyPress = (e) => {
      if (e.key === "Enter"){
        e.preventDefault();
        handleSend();
      }
    };
  
    const [showDropdown, setShowDropdown] = useState(false);
  
    // Toggle the dropdown
    const handleToggleDropdown = () => {
      setShowDropdown((prev) => !prev);
    };
  
    // const handleOptionClick = (event, options) => {
    //     messageInfo.message.buttons.map((option, idx) => (
    //         console.log(event.target.text, option.text)
    //     ));
  
    // sendMessage(option.reply);
    // setIsTyping(true);
    // const newMessage = {
    //     id: uuidv4(),
    //     sender: "user",
    //     text: option.title,
    //     timestamp: new Date(),
    // };
  
    // if (option === "Open an Account") {
    //     setOpenAccountStep(1);
    //     botReply(openAccountQuestions[0]);
    // } else {
    //     handleMockResponse(option);
    // }
    // };
  
    // const handleAccountSetup = (input) => {
    //     const nextStep = openAccountStep + 1;
    //     let isValid = false;
  
    //     switch (openAccountStep) {
    //         case 1:
    //             if (!input.trim()) {
    //                 botReply("Name is required. Please provide your name.");
    //                 return;
    //             }
    //             isValid = true;
    //             setAccountDetails({ ...accountDetails, name: input });
    //             break;
    //         case 2:
    //             const phoneRegex = /^[0-9]{10}$/;
    //             if (!phoneRegex.test(input)) {
    //                 botReply("Please enter a valid phone number.");
    //                 return;
    //             }
    //             isValid = true;
    //             setAccountDetails({ ...accountDetails, phone: input });
    //             break;
    //         case 3:
    //             const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    //             if (!emailRegex.test(input)) {
    //                 botReply("Please enter a valid email address.");
    //                 return;
    //             }
    //             isValid = true;
    //             setAccountDetails({ ...accountDetails, email: input });
    //             break;
    //         case 4:
    //             const validGenders = ["male", "female", "other"];
    //             if (
    //                 !input.trim() ||
    //                 !validGenders.includes(input.trim().toLowerCase())
    //             ) {
    //                 botReply("Please provide a valid gender (Male, Female, or Other).");
    //                 return;
    //             }
    //             isValid = true;
    //             setAccountDetails({ ...accountDetails, gender: input.trim() });
    //             break;
    //         default:
    //             break;
    //     }
    //     if (isValid) {
    //         if (openAccountStep < openAccountQuestions.length) {
    //             setOpenAccountStep(nextStep);
    //             botReply(openAccountQuestions[openAccountStep]);
    //         } else {
    //             setOpenAccountStep(0);
    //             botReply("Thank you! Your account information has been recorded.");
    //         }
    //     }
    // };
  
    // const handleClearChat = () => {
    //     setMessages([
    //         {
    //             sender: "bot",
    //             text: CHAT_BOT.WELCOME_ASSIST_TEXT,
    //             timestamp: new Date(),
    //         },
    //     ]);
    //     setOpenAccountStep(0);
    //     setAccountDetails({
    //         name: "",
    //         phone: "",
    //         email: "",
    //         gender: "",
    //     });
    // };
  
    return (
      <div className="chatbot">
        <div className="chat-header">
          <div>
            {/* <HambergerMenu
              size="30"
              // className="margin-t-2 cursor-pointer hamburger-menu-icon"
              onClick={handleToggleDropdown}
            /> */}
          </div>
          <div className="logo-text-container">
            <div className="icon-container">
              <img src={botlogo} alt="bot"/>
            </div>
            <p className="bot-title">{CHAT_BOT.CHATBOT}</p>
          </div>
          <button className="close-button">
            <ClearIcon onClick={onClose} />
          </button>
        </div>
  
        {/* {showDropdown && (
          <Stack className="dropdown-menu">
            <List>
              <ListItem className="option-item">
                <ListItemIcon className="options-icon">
                  <Trash />
                </ListItemIcon>
                <ListItemText
                  primary="Clear Conversation"
                  className="option-text"
                />
              </ListItem>
              <ListItem className="option-item">
                <ListItemIcon className="options-icon">
                  <Call />
                </ListItemIcon>
                <ListItemText primary="Contact us" className="option-text" />
              </ListItem>
              <ListItem className="option-item">
                <ListItemIcon className="options-icon">
                  <Notepad2 />
                </ListItemIcon>
                <ListItemText primary="Frequently asked questions" className="option-text" />
              </ListItem>
            </List>
          </Stack>
        )} */}
  
        <div className="chat-body">
          {messages.map((messageInfo) => (
            <ChatBody
              messageInfo={messageInfo}
              setIsTyping={setIsTyping}
              setMessages={setMessages}
            />
          ))}
  
          {/* {isTyping && (
            <div className="chat-message bot">
              <Avatar className="bot-avatar">
                <BotIcon />
              </Avatar>
              <div className="message-text">{CHAT_BOT.TYPING}</div>
            </div>
          )} */}
           {isTyping && (
            <div className="chat-message bot">
              <Avatar className="bot-avatar">
              <img src={userAvatar} alt="avatar" />
              </Avatar>
              {/* <div className="message-text">{CHAT_BOT.TYPING}</div> */}
              <div className="bot-processing bot-container" >
                <span className="dot"></span>
                <span className="dot"></span>
                <span className="dot"></span>
              </div>
            </div>
          )}
  
          <div ref={chatEndRef} />
        </div>
  
        {/* <div className="chat-footer">
          <input
            id="user-input"
            type="text"
            value={userInput}
            onChange={handleUserInput}
            onKeyDown={handleKeyPress}
            placeholder={CHAT_BOT.TYPING_PLACEHOLDER_TEXT}
          />
          <IconButton onClick={handleSend} color="primary">
            <MdSend />
          </IconButton>
        </div> */}
        <div
          style={{ position: "relative", display: "flex", alignItems: "center" ,margin:"5px"}}
        >
          <textarea 
            id="user-input"
            type="text"
            value={userInput}
            onChange={handleUserInput}
            onKeyDown={handleKeyPress}
            placeholder={CHAT_BOT.TYPING_PLACEHOLDER_TEXT}
            style={{ paddingLeft: "20px" }}
          />
          {/* <MdSend style={{ position: 'absolute', right: 8, color: 'gray' }} />  */}
          <Send
            onClick={handleSend}
            size="22"
            color="#64748B"
            style={{ position: "absolute", right: 8, color: "gray" }}
          />
        </div>
        <Stack className="caption-container">
          <Typography className="caption">{CHAT_BOT.POWERED_BY}</Typography>
        </Stack>
      </div>
    );
  };
  
  export default Chatbot;
  